import { LoginLayoutContainer } from './LoginLayout.style';
import Logo from '../assets/images/HomeConfigureLogo.png';

interface LoginLayoutProps {
    children?: React.ReactNode;
}

export function LoginLayout({ children }: LoginLayoutProps) {
    return (
        <LoginLayoutContainer>
            <img src={Logo} alt="" />
            <div className="login-form__container">{children}</div>
        </LoginLayoutContainer>
    );
}
