import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useStores } from '../hooks/useStores';
import { GlobalStyle } from '../shared/styleguide/GlobalStyle';
import Layout from '../shared/styleguide/Layout';
import LoginLayout from '../shared/styleguide/LoginLayout';
import Spinner from '../shared/styleguide/Spinner';
import ClientCredentialsRoutes from './client-credentials/ClientCredentailsRoutes';
import LoginForm from './LoginForm';
import NotFound from './NotFound';
import NotificationProvider from './notification/NotificationProvider';
import RequireAuth from './RequireAuth';
import RoleManagementRoutes from './roles-management/RoleManagementRoutes';
import { AuthenticationResult, InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { isAzureAuthEnabled } from '../Environment';
import { isSuccessEvent } from '../authConfig';

const UserList = React.lazy(() => import('./UserList'));
const UserProfile = React.lazy(() => import('./UserProfile'));

const Pages = observer(() => {
    const { authStore } = useStores();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAzureAuthEnabled()) {
            const activeAccount = instance.getActiveAccount();
            if (activeAccount && !authStore.loggedInUser) {
                authStore.getUserData(activeAccount);
            } else {
                const callbackId = instance.addEventCallback(async (event) => {
                    if (isSuccessEvent(event) && event.payload && !authStore.loggedInUser) {
                        const authenticationResult = event.payload as AuthenticationResult;
                        const account = authenticationResult.account;
                        await authStore.getUserData(account);
                    }
                });
                return () => {
                    if (callbackId) {
                        instance.removeEventCallback(callbackId);
                    }
                };
            }
        }
    }, [authStore, instance]);

    if (isAzureAuthEnabled() && (authStore.isLoading || (!isAuthenticated && inProgress !== InteractionStatus.None)))
        return <Spinner />;

    return (
        <Routes>
            <Route
                path="/login"
                element={
                    isAzureAuthEnabled() ? (
                        <Navigate to={'/user'} replace />
                    ) : (
                        <LoginLayout>
                            <LoginForm />
                        </LoginLayout>
                    )
                }
            />
            <Route element={<RequireAuth />}>
                <Route path="/" element={<Navigate replace to="/user" />} />
                <Route path="/user" element={<Layout />}>
                    <Route
                        index
                        element={
                            <Suspense fallback={<Spinner />}>
                                <UserList />
                            </Suspense>
                        }
                    />
                    <Route
                        path=":userId"
                        element={
                            <Suspense fallback={<Spinner />}>
                                <UserProfile />
                            </Suspense>
                        }
                    />
                </Route>
                <Route element={<Layout />}>
                    <Route path={'/client-credentials/*'} element={<ClientCredentialsRoutes />} />
                </Route>
                <Route element={<Layout />}>
                    <Route path={'/roles-management/*'} element={<RoleManagementRoutes />} />
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
});

type AppProps = {
    instance: PublicClientApplication;
};

function App({ instance }: AppProps) {
    return (
        <MsalProvider instance={instance}>
            <NotificationProvider>
                <GlobalStyle />
                <Pages />
            </NotificationProvider>
        </MsalProvider>
    );
}

export default observer(App);
