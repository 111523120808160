import { AxiosRequestConfig } from 'axios';
import { msalInstance } from '../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

function createBearerToken(accessToken: string): string {
    return `Bearer ${accessToken}`;
}

export const requestWithAuthorization = async (
    request: AxiosRequestConfig<any>,
    scopes: string[]
): Promise<AxiosRequestConfig<any>> => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.length > 0) {
        const accessTokenRequest = {
            scopes,
            account: accounts[0]
        };
        try {
            const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
            // Acquire token silent success
            const accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            if (request.headers) {
                request.headers.Authorization = createBearerToken(accessToken);
            }
            return await Promise.resolve(request);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
        }
    }

    return Promise.resolve(request);
};

export const requestWithAuthorizationFromToken = (
    request: AxiosRequestConfig<any>,
    tokenString: string | null
): AxiosRequestConfig<any> => {
    if (tokenString) {
        const token = createBearerToken(tokenString);

        if (request.headers) {
            request.headers.Authorization = token;
        } else {
            request.headers = {
                Authorization: token
            };
        }
    }
    return request;
};
